import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HuddleComponent } from './huddle.component';
import { HuddleViewComponent } from './huddle-view/huddle-view.component';
import { HuddleDashboardComponent } from './huddle-dashboard/huddle-dashboard.component';
import { HuddleUpcomingComponent } from './huddle-upcoming/huddle-upcoming.component';
import { HuddleHuddlesComponent } from './huddle-huddles/huddle-huddles.component';
import { HuddleCreate2Component } from './huddle-create2/huddle-create2.component';
import { CarpoolListComponent } from '../carpool/carpool-list/carpool-list.component';
import { CarpoolViewNewComponent } from '../carpool/carpool-view-new/carpool-view-new.component';
import { authGuard } from '../../utilities';
import { CarpoolEditComponent } from '../carpool/carpool-edit/carpool-edit.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    component: HuddleComponent,
    children: [
      {
        path: '',
        component: HuddleDashboardComponent,
      },
      {
        path: 'upcoming',
        component: HuddleUpcomingComponent,
      },
      {
        path: 'view/:id',
        component: HuddleViewComponent,
      },
      {
        path: 'huddles',
        component: HuddleHuddlesComponent,
      },
      {
        path: 'create',
        component: HuddleCreate2Component,
      },
      {
        path: 'carpools',
        component: CarpoolListComponent,
      },
      {
        path: 'carpool/create',
        component: CarpoolEditComponent,
      },
      {
        path: 'carpool/edit/:id',
        component: CarpoolEditComponent,
      },
      {
        path: 'carpool/view/:id',
        component: CarpoolViewNewComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HuddleRoutingModule {}
