<div class="mb-[2rem] flex max-w-full flex-col items-center">
  <div class="w-full">
    <div class="max-w-full">
      <div class="flex flex-auto justify-end pr-2 pt-2" *ngIf="!isPastHuddle">
        <div class="flex">
          <div>
            <button
              type="button"
              pButton
              (click)="openCarpoolDialog()"
              class="mp-button mp-button-outlined h-12 w-full justify-center rounded-md px-5 py-2 leading-6 transition-all md:w-auto"
            >
              Create Carpool
            </button>
          </div>
        </div>
      </div>
      <div
        class="grow-1 view-content flex flex-row flex-wrap items-stretch justify-start gap-0 px-2 pb-2 md:gap-2"
      >
        <div class="flex flex-auto flex-col justify-start gap-2 pt-2">
          <p-card styleClass="dashboard-first-row relative h-auto rounded-lg">
            <div class="px-2">
              <h4 class="highlight-text -mx-6 mx-2 py-2 font-semibold">
                At a Glance
              </h4>
              <p-divider styleClass="m-0"></p-divider>
            </div>
            <div class="relative">
              <div class="mx-0 flex justify-between p-0 md:mx-3 md:p-5">
                <div
                  class="menu-item flex flex-1 cursor-pointer flex-col items-center gap-1 rounded-md px-6 py-3 transition duration-300"
                >
                  <span class="text-2xl">{{ totalCarpools }}</span>
                  <span>Carpools</span>
                </div>
                <div
                  class="menu-item flex flex-1 cursor-pointer flex-col items-center gap-1 rounded-md px-6 py-3 transition duration-300"
                >
                  <span class="text-2xl">{{ totalCarpoolers }}</span>
                  <span>Carpoolers</span>
                </div>
                <div
                  class="menu-item flex flex-1 cursor-pointer flex-col items-center gap-1 rounded-md px-6 py-3 transition duration-300"
                >
                  <span class="text-2xl">{{ totalWaiting }}</span>
                  <span>Waiting</span>
                </div>
              </div>
            </div>
          </p-card>
          <p-card
            class="block xl:hidden"
            styleClass="dashboard-first-row relative rounded-lg"
          >
            <div class="px-2">
              <div class="grow-1 flex items-center justify-between">
                <h4 class="highlight-text -mx-6 mx-2 py-2 font-semibold">
                  Waiting Area
                </h4>
                <p-button
                  *ngIf="!isLeave && canCreateAndJoinCarpool && !isPastHuddle"
                  label="Join"
                  styleClass="primary"
                  [text]="true"
                  (click)="joinAndLeaveWaitlist()"
                ></p-button>
                <p-button
                  *ngIf="isLeave"
                  label="Leave"
                  styleClass="text-red-500"
                  [text]="true"
                  (click)="joinAndLeaveWaitlist(!1)"
                ></p-button>
              </div>
              <p-divider styleClass="m-0"></p-divider>
            </div>
            <div class="relative">
              <div
                *ngIf="!waitList.length"
                class="mx-auto h-[86px] w-auto px-2 pt-4 text-center md:h-[124px] md:w-[400px]"
              >
                Nobody requested to join
              </div>
              <p-scrollPanel
                *ngIf="waitList.length"
                [style]="{
                  width: '100%',
                  overflow: 'auto',
                }"
                styleClass="p-2 waitlist"
              >
                <div class="selected-users rounded-md">
                  <ul role="list" class="">
                    <li
                      *ngFor="let wait of waitList"
                      class="grow-1 flex-stretch ml-12 flex items-center justify-between pb-2"
                    >
                      <div class="grow-1 -ml-12 flex w-full items-center">
                        <p-avatar styleClass="mr-2" size="large" shape="circle">
                          <img
                            *ngIf="wait.profilePicUrl"
                            [src]="getProfileImageById(wait.id) | image | async"
                            alt=""
                          />
                          <img
                            *ngIf="!wait.profilePicUrl"
                            src="../assets/images/user-pic-default.svg"
                            alt="default"
                          />
                        </p-avatar>
                        <div class="flex flex-col">
                          <span>{{ wait.displayName }}</span>
                          <div class="truncate">
                            <a
                              [routerLink]="['/', wait.username]"
                              class="primary transition duration-300"
                              >&commat;{{ wait.username }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </p-scrollPanel>
            </div>
          </p-card>
          <p-card
            styleClass="p-0"
            class="basis-full md:flex-1"
            *ngFor="let carpool of evenCarpools"
          >
            <ng-template pTemplate="header">
              <div class="flex grow items-center justify-between border-b p-3">
                <div class="flex flex-1 items-center">
                  <p-avatar styleClass="mr-2" size="large" shape="circle">
                    <img
                      *ngIf="carpool.leader?.profilePicUrl"
                      [src]="getProfileImage(carpool.leader.id) | image | async"
                      alt=""
                    />
                    <img
                      *ngIf="!carpool.leader?.profilePicUrl"
                      src="../assets/images/user-pic-default.svg"
                      alt="default"
                    />
                  </p-avatar>
                  <div class="flex-1">
                    <p-button
                      *ngIf="userInfo.id !== carpool.leader.id"
                      [label]="carpool.leader.displayName"
                      styleClass="primary prevent"
                      [text]="true"
                    ></p-button>
                    <span *ngIf="userInfo.id === carpool.leader.id">You</span>

                    <span
                      >{{
                        userInfo.id === carpool.leader.id ? " are" : " is"
                      }}
                      leading this carpool</span
                    >
                  </div>
                  <p-button
                    *ngIf="canCreateAndJoinCarpool && !isPastHuddle"
                    label="Join"
                    styleClass="primary"
                    [text]="true"
                    (click)="requestToJoinCarpool(carpool.id)"
                  ></p-button>
                </div>
              </div>
            </ng-template>
            <div
              (click)="onCarpoolClick(carpool.id)"
              class="grid cursor-pointer grid-cols-1 gap-x-2 gap-y-3 px-4 pb-4 sm:grid-cols-6"
            >
              <div class="col-span-6 md:col-span-6">
                <div
                  class="mb-1 mt-2 flex grow flex-col items-start gap-3 md:flex-row md:items-center"
                >
                  <div
                    class="default-vehicle-cover relative mb-3 w-full basis-[40%] pt-[90%] md:pt-[40.33%]"
                  >
                    <div class="absolute bottom-0 left-0 right-0 top-0">
                      <img
                        *ngIf="carpool.vehicle?.id"
                        [src]="
                          getVehicleCoverPhotoUrl(carpool.vehicle.id)
                            | image
                            | async
                        "
                        alt=""
                        class="product-image h-full w-full rounded-md"
                      />
                    </div>
                  </div>
                  <div class="flex flex-1 flex-col gap-3">
                    <span class="inline-flex items-center">
                      <p-avatar styleClass="mr-2" shape="circle">
                        <img
                          *ngIf="carpool.leader.profilePicUrl"
                          [src]="
                            getProfileImage(carpool.leader.id) | image | async
                          "
                          alt=""
                        />
                        <img
                          *ngIf="!carpool.leader.profilePicUrl"
                          src="../assets/images/user-pic-default.svg"
                          alt="default"
                        />
                      </p-avatar>
                      <span>{{ carpool.leader.displayName }} (Leader)</span>
                    </span>
                    <span class="flex items-center gap-3">
                      <p-avatarGroup>
                        <p-avatar
                          *ngFor="
                            let carpooler of carpool.carpoolers.slice(0, 5)
                          "
                          shape="circle"
                          styleClass="inline-block align-middle"
                        >
                          <img
                            *ngIf="carpooler.member.profilePicUrl"
                            [src]="
                              getProfileImage(carpooler.member.id)
                                | image
                                | async
                            "
                            alt=""
                          />
                          <img
                            *ngIf="!carpooler.member.profilePicUrl"
                            src="../assets/images/user-pic-default.svg"
                            alt="default"
                          />
                        </p-avatar>
                        <p-avatar
                          *ngIf="carpool.carpoolers.length > 5"
                          [label]="
                            '+' + (carpool.carpoolers.length - 5).toString()
                          "
                          shape="circle"
                        ></p-avatar>
                      </p-avatarGroup>
                      <span>{{ carpool.carpoolers.length }} carpoolers</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-span-6 md:col-span-6">
                <span class="highlight-text">
                  <span class="form-title">Start Time:</span>
                  {{ carpool.startTime | date: "EEE, MMM d, h:mm a" }}
                </span>
              </div>
              <div class="col-span-6 md:col-span-6" *ngIf="carpool.returnTime">
                <span class="highlight-text">
                  <span class="form-title">Return Time:</span>
                  {{ carpool.returnTime | date: "EEE, MMM d, h:mm a" }}
                </span>
              </div>
              <div class="col-span-6 md:col-span-6">
                <span
                  ><span class="form-title">Start Address:</span>
                  {{ carpool.startAddress.name }}</span
                >
              </div>
              <div class="col-span-6 md:col-span-6">
                <span
                  ><span class="form-title">End Address:</span>
                  {{ carpool.endAddress.name }}</span
                >
              </div>
              <div class="col-span-6 md:col-span-6">
                <span
                  ><span class="form-title">Trip Type:</span>
                  {{
                    carpool.tripType === "ROUND"
                      ? "Round Trip"
                      : carpool.tripType === "DEPART"
                        ? "Depart Trip"
                        : "Return Trip"
                  }}</span
                >
              </div>
            </div>
          </p-card>
        </div>
        <div
          class="flex flex-auto flex-col justify-start gap-2 pt-2"
          [ngClass]="!oddCarpools.length ? 'hidden xl:flex' : ''"
        >
          <p-card
            class="hidden xl:block"
            styleClass="dashboard-first-row relative rounded-lg"
          >
            <div class="px-2">
              <div class="grow-1 flex items-center justify-between">
                <h4 class="highlight-text -mx-6 mx-2 py-2 font-semibold">
                  Waiting Area
                </h4>
                <div *ngIf="!isPastHuddle">
                  <p-button
                    *ngIf="!isLeave"
                    label="Join"
                    styleClass="primary"
                    [text]="true"
                    (click)="joinAndLeaveWaitlist()"
                  ></p-button>
                  <p-button
                    *ngIf="isLeave"
                    label="Leave"
                    styleClass="text-red-500"
                    [text]="true"
                    (click)="joinAndLeaveWaitlist(!1)"
                  ></p-button>
                </div>
              </div>
              <p-divider styleClass="m-0"></p-divider>
            </div>
            <div class="relative">
              <div
                *ngIf="!waitList.length"
                class="mx-auto h-[86px] w-auto px-2 pt-4 text-center md:h-[124px] md:w-[400px]"
              >
                Nobody requested to join
              </div>
              <p-scrollPanel
                *ngIf="waitList.length"
                [style]="{
                  width: '100%',
                  overflow: 'auto',
                }"
                styleClass="p-2 waitlist  "
              >
                <div class="selected-users rounded-md">
                  <ul role="list" class="">
                    <li
                      *ngFor="let wait of waitList"
                      class="grow-1 flex-stretch ml-12 flex items-center justify-between pb-2"
                    >
                      <div class="grow-1 -ml-12 flex w-full items-center">
                        <p-avatar styleClass="mr-2" size="large" shape="circle">
                          <img
                            *ngIf="wait.profilePicUrl"
                            [src]="getProfileImageById(wait.id) | image | async"
                            alt=""
                          />
                          <img
                            *ngIf="!wait.profilePicUrl"
                            src="../assets/images/user-pic-default.svg"
                            alt="default"
                          />
                        </p-avatar>
                        <div class="flex flex-col">
                          <span>{{ wait.displayName }}</span>
                          <div class="truncate">
                            <a
                              [routerLink]="['/', wait.username]"
                              class="primary transition duration-300"
                              >&commat;{{ wait.username }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </p-scrollPanel>
            </div>
          </p-card>
          <p-card
            styleClass="p-0"
            class="basis-full md:flex-1"
            *ngFor="let carpool of oddCarpools"
          >
            <ng-template pTemplate="header">
              <div class="flex grow items-center justify-between border-b p-3">
                <div class="flex flex-1 items-center">
                  <p-avatar styleClass="mr-2" size="large" shape="circle">
                    <img
                      *ngIf="carpool.leader?.profilePicUrl"
                      [src]="getProfileImage(carpool.leader.id) | image | async"
                      alt=""
                    />
                    <img
                      *ngIf="!carpool.leader?.profilePicUrl"
                      src="../assets/images/user-pic-default.svg"
                      alt="default"
                    />
                  </p-avatar>
                  <div class="flex-1">
                    <p-button
                      *ngIf="userInfo.id !== carpool.leader.id"
                      [label]="carpool.leader.displayName"
                      styleClass="primary prevent"
                      [text]="true"
                    ></p-button>
                    <span *ngIf="userInfo.id === carpool.leader.id">You</span>

                    <span
                      >{{
                        userInfo.id === carpool.leader.id ? " are" : " is"
                      }}
                      leading this carpool</span
                    >
                  </div>
                  <p-button
                    *ngIf="canCreateAndJoinCarpool && !isPastHuddle"
                    label="Join"
                    styleClass="primary"
                    [text]="true"
                    (click)="requestToJoinCarpool(carpool.id)"
                  ></p-button>
                </div>
              </div>
            </ng-template>
            <div
              (click)="onCarpoolClick(carpool.id)"
              class="grid cursor-pointer grid-cols-1 gap-x-2 gap-y-3 px-4 pb-4 sm:grid-cols-6"
            >
              <div class="col-span-6 md:col-span-6">
                <div
                  class="mb-1 mt-2 flex grow flex-col items-start gap-3 md:flex-row md:items-center"
                >
                  <div
                    class="default-vehicle-cover relative mb-3 w-full basis-[40%] pt-[90%] md:pt-[40.33%]"
                  >
                    <div class="absolute bottom-0 left-0 right-0 top-0">
                      <img
                        *ngIf="carpool.vehicle?.id"
                        [src]="
                          getVehicleCoverPhotoUrl(carpool.vehicle.id)
                            | image
                            | async
                        "
                        alt=""
                        class="product-image h-full w-full rounded-md"
                      />
                    </div>
                  </div>
                  <div class="flex flex-1 flex-col gap-3">
                    <span class="inline-flex items-center">
                      <p-avatar styleClass="mr-2" shape="circle">
                        <img
                          *ngIf="carpool.leader.profilePicUrl"
                          [src]="
                            getProfileImage(carpool.leader.id) | image | async
                          "
                          alt=""
                        />
                        <img
                          *ngIf="!carpool.leader.profilePicUrl"
                          src="../assets/images/user-pic-default.svg"
                          alt="default"
                        />
                      </p-avatar>
                      <span>{{ carpool.leader.displayName }} (Leader)</span>
                    </span>
                    <span class="flex items-center gap-3">
                      <p-avatarGroup>
                        <p-avatar
                          *ngFor="
                            let carpooler of carpool.carpoolers.slice(0, 5)
                          "
                          shape="circle"
                          styleClass="inline-block align-middle"
                        >
                          <img
                            *ngIf="carpooler.member.profilePicUrl"
                            [src]="
                              getProfileImage(carpooler.member.id)
                                | image
                                | async
                            "
                            alt=""
                          />
                          <img
                            *ngIf="!carpooler.member.profilePicUrl"
                            src="../assets/images/user-pic-default.svg"
                            alt="default"
                          />
                        </p-avatar>
                        <p-avatar
                          *ngIf="carpool.carpoolers.length > 5"
                          [label]="
                            '+' + (carpool.carpoolers.length - 5).toString()
                          "
                          shape="circle"
                        ></p-avatar>
                      </p-avatarGroup>
                      <span>{{ carpool.carpoolers.length }} carpoolers</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-span-6 md:col-span-6">
                <span class="highlight-text">
                  <span class="form-title">Start Time:</span>
                  {{ carpool.startTime | date: "EEE, MMM d, h:mm a" }}
                </span>
              </div>
              <div class="col-span-6 md:col-span-6" *ngIf="carpool.returnTime">
                <span class="highlight-text">
                  <span class="form-title">Return Time:</span>
                  {{ carpool.returnTime | date: "EEE, MMM d, h:mm a" }}
                </span>
              </div>
              <div class="col-span-6 md:col-span-6">
                <span
                  ><span class="form-title">Start Address:</span>
                  {{ carpool.startAddress.name }}</span
                >
              </div>
              <div class="col-span-6 md:col-span-6">
                <span
                  ><span class="form-title">End Address:</span>
                  {{ carpool.endAddress.name }}</span
                >
              </div>
              <div class="col-span-6 md:col-span-6">
                <span
                  ><span class="form-title">Trip Type:</span>
                  {{
                    carpool.tripType === "ROUND"
                      ? "Round Trip"
                      : carpool.tripType === "DEPART"
                        ? "Depart Trip"
                        : "Return Trip"
                  }}</span
                >
              </div>
            </div>
          </p-card>
        </div>
      </div>
    </div>
  </div>
</div>

<app-dialog
  [visibleChange]="dialogEvent.asObservable()"
  [view]="'CARPOOL'"
  header="Create"
  width="50vw"
  [userDetails]="userInfo"
  (huddleDialogData)="onHuddleData($event)"
>
</app-dialog>

<div class="overlay" *ngIf="isLoading">
  <p-progressSpinner styleClass="horizontal-center" />
</div>
